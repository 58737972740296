import { lazy, LazyExoticComponent } from "react";

export const ROOT = "/";
export const HOME = "/home";

export type TPath = {
	path: string;
	exact: boolean;
	element: LazyExoticComponent<() => JSX.Element>;
	isPrivate?: boolean;
};

export const paths: TPath[] = [
	{
		path: "/editor",
		exact: true,
		element: lazy(() => import("../pages/editor")),
	},
];
