import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";

// import { v4 as uuidv4 } from "uuid";

// import { EditorService } from "~/services";

export type TSaveModel = {
	open: boolean;
	setOpen: (value: boolean) => void;
	setLoading?: (value: boolean) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	modelo?: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	channels?: any;
	salvarRascunho?: () => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	createTemplate?: (value: any) => void;
	modalModelo?: () => void;
};

export const SaveModel = ({ open, setOpen }: TSaveModel) => {
	const handleCancel = () => {
		setOpen(false);
	};

	const handleOk = () => {
		// setLoading(true);

		// modelo.templateReferenceKey = modelo.templateReferenceKey ? modelo.templateReferenceKey : uuidv4();
		// if (modelo.new.active) {
		// 	modelo.templateReferenceKey = uuidv4();
		// }

		// modelo.error = false;

		// if ((modelo.title.trim() !== "" && modelo.new.active == false) || (modelo.new.title.trim() !== "" && modelo.new.active == true)) {
		// 	modelo.show = true;
		// 	if (modelo.new.active) {
		// 		modelo.title = modelo.new.title;
		// 		modelo.new.active = false;

		// 		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		// 		channels.forEach(async (channelItem: any) => {
		// 			if (channelItem.Selected) {
		// 				createTemplate(channelItem);
		// 			}
		// 		});
		// 	} else {
		// 		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		// 		channels.forEach(async (channelItem: any) => {
		// 			if (channelItem.Selected) {
		// 				// eslint-disable-next-line @typescript-eslint/no-explicit-any
		// 				const modeloId = modelo.channels.find((channel: any) => channel.Name === channelItem.Name).ModeloId;
		// 				if (modeloId > 0) {
		// 					try {
		// 						const { data } = await EditorService.saveEditor(modeloId, {
		// 							channel: channelItem.Name,
		// 							name: modelo.title.trim(),
		// 							// eslint-disable-next-line @typescript-eslint/no-explicit-any
		// 							json: JSON.stringify(channels.find((channel: any) => channel.Name === channelItem.Name).engine),
		// 							templateReferenceKey: modelo.templateReferenceKey,
		// 						});

		// 						setLoading(false);
		// 						modelo.loading = false;
		// 						modelo.permissions.edit = data.permissions.edit;
		// 						// eslint-disable-next-line @typescript-eslint/no-explicit-any
		// 						modelo.channels.find((channel: any) => channel.Name === channelItem.Name).ModeloId = data.id;
		// 						modelo.id = data.id;
		// 						salvarRascunho();
		// 					} catch (err) {
		// 						setLoading(false);
		// 						modelo.loading = false;
		// 						modelo.error = true;

		// 						// if (err.response.status === 403) {
		// 						// 	$store.commit("setNotification", {
		// 						// 		type: "error",
		// 						// 		content: "Você não está autorizado a realizar essa ação.",
		// 						// 	});
		// 						// } else {
		// 						// 	$store.commit("setNotification", {
		// 						// 		type: "error",
		// 						// 		content: $t("Ocorreu uma falha de conexão ao salvar o seu favorito."),
		// 						// 	});
		// 						// }
		// 					}
		// 				} else {
		// 					createTemplate(channelItem);
		// 				}
		// 			}
		// 		});
		// 	}
		// 	modalModelo();
		// } else {
		// 	setLoading(false);
		// }

		setOpen(false);
	};

	return (
		<Dialog sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }} maxWidth="xs" open={open}>
			<DialogTitle>Deseja salvar em "meus templates"?</DialogTitle>

			<DialogContent dividers>
				<TextField fullWidth label="Defina um Nome" />
			</DialogContent>

			<DialogActions>
				<Button variant="contained" onClick={handleOk}>
					Favoritar
				</Button>
				<Button variant="contained" color="secondary" autoFocus onClick={handleCancel}>
					Cancelar
				</Button>
			</DialogActions>
		</Dialog>
	);
};
