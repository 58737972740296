import { Tooltip, Typography, TypographyProps } from "@mui/material";

export type TEllipsisTextDynamic = TypographyProps & {
	text: string;
	customTooltip?: string;
	textExtra?: string;
};

export const EllipsisTextDynamic = ({ text, customTooltip = "", textExtra = "", ...others }: TEllipsisTextDynamic): JSX.Element => {
	return (
		<Tooltip title={customTooltip || text}>
			<span style={{ display: "flex" }}>
				<Typography
					style={{
						whiteSpace: "nowrap",
						overflow: "hidden",
						textOverflow: "ellipsis",
					}}
					{...others}
				>
					{text}
				</Typography>
				<Typography style={{ whiteSpace: "nowrap" }}>{textExtra}</Typography>
			</span>
		</Tooltip>
	);
};

export default EllipsisTextDynamic;
