import React, { useRef, useEffect, useState } from "react";

import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledText = styled("span")(() => ({
	"p, span, div": {
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		width: "100%",
	},
}));

export type TEllipsisTextOverflow = {
	tooltip: string;
	children: JSX.Element;
};

export const EllipsisTextOverflow = ({ children, tooltip }: TEllipsisTextOverflow) => {
	const textElementRef = useRef<HTMLDivElement>();

	// Define state and function to update the value
	const [hoverStatus, setHover] = useState(false);

	const compareSize = () => {
		if (!textElementRef || !textElementRef.current) return;

		const compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;

		setHover(compare);
	};

	// compare once and add resize listener on "componentDidMount"
	useEffect(() => {
		compareSize();
		window.addEventListener("resize", compareSize);
	}, []);

	// remove resize listener again on "componentWillUnmount"
	useEffect(
		() => () => {
			window.removeEventListener("resize", compareSize);
		},
		[]
	);

	return (
		<Tooltip title={tooltip} disableHoverListener={!hoverStatus} style={{ fontSize: "2em", width: "100%" }}>
			<StyledText>{React.cloneElement(children, { ref: textElementRef })}</StyledText>
		</Tooltip>
	);
};
