import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

import { colors } from "~/styles";

import { MenuContainer } from "./components/menu";

export const BoxBarCustomLarge = styled(Box)(() => ({
	"::-webkit-scrollbar": {
		width: "12px",
	},
	"::-webkit-scrollbar-track": {
		borderRadius: "5px",
		background: colors.gray2,
	},
	"::-webkit-scrollbar-thumb": {
		background: colors.gray5,
		borderRadius: "14px",
		height: "2px",
	},
	"::-webkit-scrollbar-thumb:hover": {
		background: colors.gray4,
		maxHeight: "8px",
	},
}));

export type TContainerMenuView = {
	children: JSX.Element;
	printComponent?: React.MutableRefObject<Element>;
};

export const ContainerMenuView = ({ children }: TContainerMenuView) => {
	return (
		<Box display="flex" flexDirection="column" width="100%" sx={{ flexGrow: "1", flexWrap: "nowrap" }}>
			<MenuContainer />

			<BoxBarCustomLarge
				sx={{
					flexGrow: "1",
					borderRight: "1px solid #DEE2E6",
					height: "calc(100vh - 80px)",
					overflow: "auto",
					position: "relative",
				}}
			>
				<Box pt={5.25} pb={4} px={10}>
					{children}
				</Box>
			</BoxBarCustomLarge>
		</Box>
	);
};
