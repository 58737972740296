import React, { Suspense } from "react";
// import { useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";

import { Box } from "@mui/material";

import { Loading } from "~/components";
// import { RootState } from "~/store";

import { paths, TPath } from "./CONSTANTS";
import { ContainerMenuContainer } from "./container-menu";
import { NotFound } from "./NotFound";
import { PrivateRoute } from "./PrivateRoute";

export const RouterConfig: React.FC = () => {
	// const { isLogged } = useSelector((state: RootState) => state.auth);

	const authToken = JSON.parse(localStorage.getItem("cin-functions-token") || "{}")?.value;

	return (
		<Routes>
			<Route path="/" element={<Navigate to={!authToken ? "login" : "editor"} replace />} />

			{paths.map((p: TPath) => {
				const { path, element: Element, isPrivate = true, ...rest } = p;

				return (
					<Route
						key={path}
						path={path}
						element={
							<Box>
								{isPrivate && (
									<Suspense fallback={<Loading />}>
										<PrivateRoute>
											<ContainerMenuContainer>
												<Element />
											</ContainerMenuContainer>
										</PrivateRoute>
									</Suspense>
								)}

								{!isPrivate && (
									<Suspense fallback={<Loading />}>
										<Element />
									</Suspense>
								)}
							</Box>
						}
						{...rest}
					/>
				);
			})}

			{/* List a generic 404-Not Found route here */}
			<Route path="*" element={<NotFound />} />
		</Routes>
	);
};
