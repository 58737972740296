import { ContainerMenuView } from "./ContainerMenuView";

export type TContainerMenuContainer = {
	children: JSX.Element;
	printComponent?: React.MutableRefObject<Element>;
};

export const ContainerMenuContainer = ({ children, printComponent }: TContainerMenuContainer): JSX.Element => {
	return <ContainerMenuView children={children} printComponent={printComponent} />;
};
