import { BrowserRouter } from "react-router-dom";

import { setDefaultOptions } from "date-fns";
import { ptBR } from "date-fns/locale";

import { ThemeProvider } from "@mui/material/styles";

import { RouterConfig } from "~/navigation";
import { customTheme } from "~/styles";

import "./App.scss";
import "react-toastify/dist/ReactToastify.css";

setDefaultOptions({ locale: ptBR });

const App = () => {
	const authToken = JSON.parse(localStorage.getItem("cin-functions-token") || "{}")?.value;

	if (!authToken) {
		localStorage.setItem(
			"cin-functions-token",
			JSON.stringify({
				value:
					"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjaW4iOiJ7XCJpZFwiOlwiMjM0NmJiMGEtMGIxNi00NDNhLTlmZTEtNDI2MmE1ZGQ0YWVhXCIsXCJyb2xlXCI6XCItMVwiLFwidHlwZVwiOlwiZW1wbG95ZWVcIixcImNpZFwiOjIsXCJja3lcIjpcImRldmVsb3BtZW50LWNvbXVuaWNhXCIsXCJ1aWRcIjoyMzUsXCJ1ZW1cIjpcIm5pY29sbGFzQGNvbXVuaWNhLmluXCIsXCJjc3J2XCI6XCJDdXN0b21lcnMwMVwiLFwiY2RiXCI6XCJkZXZlbG9wbWVudC1jb211bmljYVwifSIsImlhdCI6MTcxMjgzOTMxMCwiZXhwIjo0MzA0ODM5MTU0fQ.l8xwfhMrIfeHbTs599hKThS36JryVOlBsqqyPyxEmSk",
				expire: 1715431315345,
			})
		);
	}

	return (
		<ThemeProvider theme={customTheme({ darkMode: false })}>
			<BrowserRouter>
				<RouterConfig />
			</BrowserRouter>
		</ThemeProvider>
	);
};

export default App;
