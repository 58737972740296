// import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

// import { RootState } from "~/store";

export type TPrivateRoute = {
	children: JSX.Element;
};

export const PrivateRoute = ({ children }: TPrivateRoute) => {
	// const { isLogged } = useSelector((state: RootState) => state.auth);

	const authToken = JSON.parse(localStorage.getItem("cin-functions-token") || "{}")?.value;

	return authToken ? children : <Navigate to="/login" replace />;
};
