import { Box, Typography, Button } from "@mui/material";

export const NotFound = () => (
	<Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap={4} sx={{ width: "100vw", height: "100vh" }}>
		<Typography textAlign="center" variant="h2">
			Página não encontrada
		</Typography>
		<Typography textAlign="center" color="#495057" maxWidth="590px">
			Desculpe, não conseguimos encontrar essa página, ela pode ter sido removida ou não existe mais.
		</Typography>

		<Button variant="contained" color="primary" href="/">
			Ir para página inicial
		</Button>
	</Box>
);
