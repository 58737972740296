import { useState } from "react";

import { Typography, Box, IconButton, Button, SvgIcon, Rating, TooltipProps, Tooltip, tooltipClasses } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Close, VisibilityOutlined, HelpOutlineOutlined } from "@mui/icons-material";

import { ReactComponent as ComunicaLogo } from "~/assets/comunica-logo.svg";
import { colors } from "~/styles";

import { SaveModel } from "./SaveModel";

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} arrow classes={{ popper: className }} />)(
	({ theme }) => ({
		[`& .${tooltipClasses.arrow}`]: {
			color: theme.palette.common.black,
		},
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: theme.palette.common.black,
		},
	})
);

export const ButtonBack = styled(Box)(() => ({
	"&:hover": {
		cursor: "pointer",
	},
}));

export type TMenuView = {
	handleGoBack: () => void;
};

export const MenuView = ({ handleGoBack }: TMenuView) => {
	const [openSave, setOpenSave] = useState<boolean>(false);

	return (
		<>
			<Box display="flex" justifyContent="center" height="50px" sx={{ borderBottom: "1px solid #dcdee6" }}>
				<Box display="flex" justifyContent="center" alignItems="center" flex="0 0 100px" py={1.5} sx={{ borderRight: "1px solid #dcdee6" }}>
					<SvgIcon sx={{ width: 24.5, height: 28.14 }} component={ComunicaLogo} viewBox={`0 0 110.96 127.46`} onClick={handleGoBack} />
				</Box>

				<Box display="flex" justifyContent="center" alignItems="center" flex="1 1 calc(100% - 300px)" px={1.25}>
					<BootstrapTooltip title="Salvar favorito">
						<Rating name="salva-modelo" max={1} value={null} onClick={() => setOpenSave(true)} />
					</BootstrapTooltip>

					<Typography fontSize="17px" fontWeight="500" color={colors.gray11} ml={2}>
						Comunicado em construção
					</Typography>
				</Box>

				<Box display="flex" justifyContent="center" alignItems="center" flex="0 0 180px" gap={0.5} pr={1.25}>
					<IconButton type="button" aria-label="search">
						<HelpOutlineOutlined />
					</IconButton>

					<IconButton type="button" aria-label="search">
						<VisibilityOutlined />
					</IconButton>

					<Button variant="contained" sx={{ ml: 1.2 }}>
						Enviar
					</Button>
				</Box>

				<Box display="flex" justifyContent="center" alignItems="center" flex="0 0 50px" sx={{ borderLeft: "1px solid #dcdee6" }}>
					<IconButton type="button" aria-label="search" onClick={handleGoBack}>
						<Close />
					</IconButton>
				</Box>
			</Box>

			<SaveModel open={openSave} setOpen={setOpenSave} />
		</>
	);
};
