import { createTheme } from "@mui/material/styles";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { colors } from "./colors";

declare module "@mui/material/styles" {
	interface TypographyVariants {
		heading2: React.CSSProperties;
		heading3: React.CSSProperties;
		heading4: React.CSSProperties;
		heading5: React.CSSProperties;
		heading6: React.CSSProperties;
	}

	// allow configuration using `createTheme`
	interface TypographyVariantsOptions {
		heading2?: React.CSSProperties;
		heading3: React.CSSProperties;
		heading4: React.CSSProperties;
		heading5: React.CSSProperties;
		heading6?: React.CSSProperties;
	}
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
	interface TypographyPropsVariantOverrides {
		heading2: true;
		heading3: true;
		heading4: true;
		heading5: true;
		heading6: true;
	}
}

export const customTheme = ({ darkMode }: { darkMode: boolean }) =>
	createTheme({
		palette: {
			mode: darkMode ? "dark" : "light",
			...(darkMode
				? {
						primary: {
							main: colors.purple0,
						},
						secondary: {
							main: colors.purple1,
							contrastText: colors.purple0,
						},
					}
				: {
						primary: {
							main: colors.purple0,
						},
						secondary: {
							main: colors.purple1,
							contrastText: colors.purple0,
						},
					}),
		},

		typography: {
			fontFamily: ["Inter", "Arial", "sans-serif"].join(","),
			heading2: {
				fontFamily: "Inter",
				fontStyle: "normal",
				fontWeight: 700,
				fontSize: "8px",
				lineHeight: "39px",
			},
			heading3: {
				fontFamily: "Inter",
				fontStyle: "normal",
				fontWeight: 700,
				fontSize: "28px",
				lineHeight: "34px",
			},
			heading4: {
				fontFamily: "Inter",
				fontStyle: "normal",
				fontWeight: 700,
				fontSize: "24px",
				lineHeight: "29px",
			},
			heading5: {
				fontFamily: "Inter",
				fontStyle: "normal",
				fontWeight: 700,
				fontSize: "21px",
				lineHeight: "25px",
			},
			heading6: {
				fontFamily: "Inter",
				fontStyle: "normal",
				fontWeight: 700,
				fontSize: "19px",
				lineHeight: "22.99px",
			},
		},

		components: {
			MuiSelect: {
				defaultProps: {
					// The props to change the default for.
					IconComponent: KeyboardArrowDownIcon,
				},
				styleOverrides: {
					root: {
						fontSize: "13px",
						fontWeight: "400",
						lineHeight: "16px",
						color: colors.gray2,

						"& .MuiOutlinedInput-notchedOutline": {
							border: "1px solid #00000033",
							borderRadius: "10px",
						},

						"& .MuiSelect-select": {
							padding: "12px 32px 12px 18px",
						},
					},
				},
			},
			MuiTextField: {
				defaultProps: {
					// The props to change the default for.
					variant: "outlined",
				},
				styleOverrides: {
					root: {
						fontSize: "13px",
						fontWeight: "400",
						lineHeight: "16px",
						color: colors.gray2,

						"& .MuiInputLabel-root": {
							transform: "translate(14px, 5px) scale(1)",
							"-webkit-transform": "translate(14px, 5px) scale(1)",

							"&.Mui-focused": {
								transform: "translate(14px, -9px) scale(0.75)",
								"-webkit-transform": "translate(14px, -9px) scale(0.75)",
							},

							"&.MuiInputLabel-shrink": {
								transform: "translate(14px, -9px) scale(0.75)",
								"-webkit-transform": "translate(14px, -9px) scale(0.75)",
							},
						},

						"& .MuiOutlinedInput-notchedOutline": {
							border: "1px solid #EAEAEA",
							borderRadius: "5px",
						},

						"& .MuiInputBase-input": {
							padding: "4px 8px 4px 8px",
							height: "24px",
						},

						"& .Mui-disabled": {
							color: "#747474",
							"-webkit-text-fill-color": "#747474",
						},
					},
				},
			},
			MuiSwitch: {
				styleOverrides: {
					root: () => ({
						width: 31,
						height: 16,
						padding: 0,
						margin: "0px",
						borderRadius: "60px",
						"& .MuiSwitch-switchBase": {
							padding: 0,
							margin: "2px 3px",
							transitionDuration: "300ms",
							"&.Mui-checked": {
								margin: "2px 0px",
								transform: "translateX(16px)",
								color: "#fff",
								"& + .MuiSwitch-track": {
									opacity: 1,
								},
							},
						},
						"& .MuiSwitch-thumb": {
							width: 12,
							height: 12,
						},
						"& .MuiSwitch-track": {
							borderRadius: "60px",
						},
					}),
				},
			},
			MuiButton: {
				defaultProps: {
					// The props to change the default for.
					disableElevation: false,
				},
				styleOverrides: {
					root: {
						textTransform: "initial",
						padding: "5px 15px",
						borderRadius: "6px",
						fontSize: "12px",
						lineHeight: "16px",
						fontWeight: "500",
						boxShadow: "none",
						border: "1px solid transparent",
						transition: "all .3s ease-in-out",

						"&:hover": {
							backgroundColor: "#fff",
							borderColor: colors.purple0,
							color: colors.purple0,
							boxShadow: "none",
						},
					},
				},
			},
		},
	});
