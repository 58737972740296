import { useNavigate } from "react-router-dom";

import { MenuView } from "./MenuView";

export const MenuContainer = (): JSX.Element => {
	const navigate = useNavigate();

	const handleGoBack = () => {
		navigate("/");
	};

	return <MenuView handleGoBack={handleGoBack} />;
};
